import React, {useState, useEffect,useCallback} from 'react'
import ListItem from '../components/ListItem';
import AddButton from '../components/AddButton'
import axiosConfig from '../api/axiosConfig';

const NotesListPage = () => {

  let api = axiosConfig();
  let[notes, setNotes] = useState([]);

  let getNotes = useCallback(async () => {
    try {
      const response = await api.get('', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const responseData = Array.from(response.data);
      setNotes(responseData);
    } catch (error) {
      console.error('Request failed:', error);
    }
  }, [api]);

  useEffect(() => {
    getNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='notes'>
        <div className='notes-header'>
            <h2 className='notes-title'>&#9782; Notes</h2>
            <p className='notes-count'>{notes?.length}</p>
        </div>
        <div className='notes-list'>
            {notes?.map((note) => (
                <ListItem key={note?.id} note={note} />
            ))}
        </div>
        <AddButton />
    </div>
  )
}

export default NotesListPage