import axios from "axios";

const BASE_URL = "https://notesapi-spring.onrender.com/api/v1/notes";

const axiosConfig = () => {

    const axiosInstance = axios.create({
        baseURL:BASE_URL,
        headers:{
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
        }
    });

    return axiosInstance;
}
export default axiosConfig;