import React, { useState,useEffect,useCallback } from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import { ReactComponent as ArrowLeft } from '../assets/arrow-left.svg'
import axiosConfig from '../api/axiosConfig'

const NotePage = () => {
    let api = axiosConfig();
    let noteId = useParams();
    let navigate = useNavigate();

    let [note, setNote] = useState();

    // let getNote = async () => {
    //     if (noteId.id === 'new') return;
      
    //     try {
    //       const response = await api.get(`/${noteId.id}`);
    //       setNote(response.data);
    //       console.log(response.data);
    //     } catch (error) {
    //       console.error('Error fetching note:', error);
    //       // Handle the error, for example, set an error state
    //     }
    //   };

      let getNote = useCallback(async () => {
        if (noteId.id === 'new') return;
      
        try {
          const response = await api.get(`/${noteId.id}`);
          setNote(response.data);
          console.log(response.data);
        } catch (error) {
          console.error('Error fetching note:', error);
          // Handle the error, for example, set an error state
        }
      }, [api,noteId]);
      
      useEffect(() => {
        getNote();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    let createNote = async () => {
        await api.post('',note).then((response) => {
            console.log(response.data)
        })
    }

    let updateNote = async () => {
        await api.put('',note).then((response) => {
            console.log(response.log)
        })
        navigate("/")
        navigate(0)
    }

    let deleteNote = async () => {
        await api.delete(`/${noteId.id}`).then((response) => {
            console.log(response.data)
        })
        navigate("/")
        navigate(0)
    }

    let handleSubmit = () => {
        if(noteId.id !== 'new' && !note.body){
            deleteNote();
        }if(noteId.id === 'new' && note != null){
            createNote();
        }else if(noteId.id !== 'new'){
            updateNote();
        }
        navigate("/")
    }
    return (
        <div className='note'>
            <div className='note-header'>
                <h3>
                    <ArrowLeft onClick={handleSubmit}/>
                </h3>
                {noteId.id !== 'new' ? (
                    <button onClick={deleteNote}>Delete</button>
                ): (
                    <button onClick={handleSubmit}>Done</button>
                )}
            </div>
            <textarea onChange={(e) => {setNote({...note, 'body': e.target.value})}} value={note?.body}/>
        </div>
  )
}

export default NotePage